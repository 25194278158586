.nx_modal_wrapper {
  position: relative;
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-width: 300px;

  .nx_modal_title {
  }

  .nx_buttons {
    position: relative;
    display: flex;
    column-gap: 25px;
    align-items: center;
    justify-content: right;

    > button {
      min-width: 90px;
    }
  }
}

.set_password_wrapper {
  position: relative;
  margin: 20px 0;
  gap: 50px;
}
