@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }

  .print-container {
    display: block;
  }

  .page {
    width: 210mm;
    height: 297mm;
    page-break-after: always;
  }

  .barcode-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 29.5mm;
    justify-content: center;
    align-items: start;
    border: 1px solid black;
    // gap: 10mm;
    padding: 10;
    background: white;
    box-sizing: border-box;
  }
  .page-break {
    page-break-after: always;
    display: block;
    height: 0;
  }

  .barcode-grid-locators {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 27.5mm;
    justify-content: center;
    align-items: start;
    border: 1px solid black;
    // gap: 10mm;
    padding: 10;
    background: white;
    box-sizing: border-box;
  }

  .barcode-container {
    width: 100%;
    height: 29.5mm; /* ✅ Ensures each cell takes up full height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 1px solid black; /* ✅ Adds a border around each cell */
  }

  .barcode-container-locators {
    width: 100%;
    height: 29.5mm; /* ✅ Ensures each cell takes up full height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 1px solid black; /* ✅ Adds a border around each cell */
  }
  .print-page-locators {
    height: 100vh; /* Adjust to fit exact print page size */
    max-height: 100vh; /* Prevents overflowing */
    overflow: hidden;
  }

  .print-page:last-child .page {
    page-break-after: auto;
  }
}

body {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Calibri;
  font-size: 16px;
  font-weight: normal;
}

html,
#root {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: Poppins !important;
  overflow: hidden;

  * {
    font-family: Poppins !important;
    scrollbar-color: #dee2e7 #fff;
    scrollbar-width: thin !important;

    ::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border: 4.5px solid transparent;
      border-left-width: 2px;
      border-right-width: 2px;
      border-radius: 2px;
      background-clip: content-box;
    }

    ::-webkit-scrollbar {
      background-color: #fff;
      width: 11px;
      overflow: visible;
      height: 15px;
    }
  }

  .uf_loading {
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }
}

.projectWrapper {
  width: 100%;
  margin: 0 10px;
  display: grid;
  height: 100%;
  position: relative;
  grid-template-rows: auto;
  grid-template-columns: repeat(5, 1fr);

  .projectFlowCard {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px #0000001a;
    height: 100%;
    text-align: left;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    max-height: 320px;
    max-width: 350px;
    width: 100%;
    display: grid;
    grid-template-rows: 50px auto;

    .projectFlowCardContentAddNew {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .projectFlowCardTitle {
      color: #ffffff;
      border-radius: 5px;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      background: #6e8efb;
      height: 100%;
      align-content: center;
      border-radius: 12px 12px 0 0;
    }

    .projectFlowCardTitleWrapper {
      display: flex;
      align-items: center;
      background: #6e8efb;
      position: relative;
      border-radius: 12px 12px 0 0;
      width: 100%;

      button {
        color: #fff;
      }

      .projectFlowSettings {
        position: absolute;
        left: 0px;
        top: 5px;
      }

      .projectFlowDelete {
        position: absolute;
        right: 0px;
        top: 5px;
      }

      .projectFlowCardTitle {
        text-align: center;
        width: 100%;
      }
    }

    .projectFlowCardContent {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 5px 0;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-items: center;
      cursor: pointer;

      .projectFlowCardContentRow {
        width: 95%;
        display: flex;
        justify-content: space-between;

        .projectFlowCardContentRowLabel {
          font-size: 18px;
          font-weight: 600;
        }

        .projectFlowCardContentRowValue {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .projectFlowCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.projectDetailsWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 50px calc(100% - 50px);
  gap: 10px;
}
