#root > .uf_login {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0;
   margin: 0;
   height: 100%;
   width: 100%;
   //background-color: #e8f2ff;
   background-image: linear-gradient(to right, #e8f2ff, #9dc2e5);
}

.uf_login_card {
   position: relative;
   width: 810px;
   height: 434px;

   .uf_login_logo_section {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 405px;
      height: 434px;
      left: 0px;
      top: 0px;
      background: rgba(30, 30, 30, 0.7);
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 20px 0px 0px 20px;

      .uf_login_logo {
         position: relative;
         // width: 188px;
         background-size: contain;
      }

      .ro_logo {
         width: 188px;
         background-repeat: no-repeat;
         background-size: contain;
      }

      .fm_logo {
         width: 188px;
         background-repeat: no-repeat;
         background-size: contain;
      }
   }

   .uf_login_login {
      position: absolute;
      width: 405px;
      height: 434px;
      left: 405px;
      top: 0px;
      background: #f8fafb;
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.15);
      border-radius: 0px 20px 20px 0px;

      .uf_login_text {
         position: relative;
         text-align: center;
         font-family: 'Poppins';
         font-style: normal;
         font-weight: 600;
         font-size: 28px;
         margin-top: 45px;
         margin-bottom: 52px;
         line-height: 33px;
         color: rgba(34, 42, 96, 0.9);
      }

      .uf_login_button_container {
         display: flex;
         width: 100%;
         position: relative;
         justify-content: center;
         align-items: center;
         margin-top: 40px;

         .uf_login_login_button {
            width: 338px;
            height: 44px;
            flex-shrink: 0;
            border-radius: 10px;
            background: rgba(45, 93, 252, 0.9);
            box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, -1px -1px 2px 0px rgba(0, 0, 0, 0.25) inset;
            color: #f8fafb;
            font-size: 18px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            line-height: normal;
         }
      }

      .uf_login_textfield_wrapper {
         position: relative;
         width: 338px;
         height: 80px;
         margin: 0 34px 0 33px;

         .uf_login_textfield {
            box-sizing: border-box;
            width: 338px;
            height: 46px;
            border-radius: 10px;
            border: 1px solid rgba(45, 93, 252, 0.5);
            background: #fbfbfc;
            box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15) inset, -1px -1px 2px 0px rgba(0, 0, 0, 0.15) inset;
         }

         .uf_login_textfield > div > fieldset {
            border: none;
         }
      }
   }
}
