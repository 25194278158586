img {
   cursor: pointer;
}

.uf_datepicker > div > input {
   font-size: 15px;
   text-transform: capitalize;
   color: rgba(0, 0, 0, 0.7);
}

.uf_datepicker > p {
   position: absolute;
   bottom: -21px;
}

.uf_textfield_large > div > input {
   position: relative;
   width: 100%;
}

// Smart control
.sc_error {
   border: 1px solid #d65b4d !important;
   border-radius: 5px !important;
}

.sc_error_msg {
   position: absolute;
   bottom: -15px;
   font-size: 11px;
   line-height: 1;
   display: flex;
   align-items: flex-start;
   padding: 0 0 0 1px;
   margin: 0;
   color: #d65b4d;
}

.uf_text_align_left {
   text-align: left !important;
}

.uf_table {
   width: 100%;
   border-collapse: collapse;

   th {
      text-align: center;
   }

   td {
      text-align: center;
   }
}

.uf_table_label {
   font-weight: 600;
   margin-bottom: 20px;
}

tr {
   border-bottom: 1pt solid rgba(0, 0, 0, 0.12);
}

tr:hover {
   background-color: #edeeee;
}

.uf_table_sublabel {
   font-size: 15px;
   color: rgba(0, 0, 0, 0.54);
}

.border_box {
   position: relative;
   height: 20px;
   width: 100%;
   display: flex;
   border-radius: 8px;
   border: 1px solid rgba(34, 42, 96, 0.5);
   font-size: 15px !important;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   color: black;
}

.nx_file_description {
   font-size: 10px !important;
}

.border_box:hover {
   border-radius: 8px;
   border: 1px solid rgba(45, 93, 252, 0.75);
}

// .border_box:focus-within {
//   border-radius: 8px;
//   border: 1px solid rgba(45, 93, 252, 1);
// }

.invisible {
   display: none !important;
}

.nx_ctrl_file_wrapper {
   position: relative;
   display: flex;
   align-items: center;
   //height: 40px;
   color: rgba(0, 0, 0, 0.7) !important;

   > div > div > input {
      height: 22px;
      padding: 9px 9px 9px 14px;
      width: 80% !important;
      font-size: 15px !important;
      color: rgba(0, 0, 0, 0.7);
   }
}

.nx_ctrl_file_wrapper > div > p {
   position: absolute;
   bottom: -21px;
}

.image_icon {
   margin-left: 0 !important;
   right: 0;
   position: absolute;
}

.image_preview {
   position: absolute;
   right: 50px;
   top: 7px;
}

::-webkit-file-upload-button {
   display: none;
}

.nx_select {
   font-size: 15px !important;
   text-transform: capitalize;
}

.nx_checkbox {
   height: 30px !important;
   color: rgba(34, 42, 96, 0.5);
   padding-left: 5px !important;
   cursor: pointer !important;
   user-select: none !important;
}

.nx_checkbox:hover {
   color: rgba(45, 93, 252, 0.75);
}

.nx_checkbox:focus-within {
   color: rgba(45, 93, 252, 1);
}

.nx_checkbox > label > span {
   font-size: 15px !important;
   text-transform: capitalize;
   color: rgba(0, 0, 0, 0.7);
}

.error {
   border-radius: 8px;
   border: 1px solid #d32f2f !important;
   color: #d32f2f !important;
}

.nx_error > div {
   border-radius: 8px;
   border: 1px solid #d32f2f !important;
}

.nx_error > label {
   color: #d32f2f !important;
}

.nx_textfield > .MuiInputLabel-root.Mui-focused,
.nx_textfield > .MuiInputLabel-root.MuiFormLabel-filled {
   transform: translate(10px, -20px) scale(0.75);
   transition: transform 0.3s ease-in-out;
}

.nx_textfield > div {
   border-radius: 8px;
   border: 1px solid rgba(34, 42, 96, 0.5);
   // padding: 0 !important;
}

.nx_autocomplete > div {
   padding: 1px !important;
}

.nx_autocomplete > .MuiInputLabel-root.Mui-focused .MuiInputLabel-root.MuiFormLabel-filled {
   transform: translate(10px, -10px) scale(0.75) !important;
   transition: transform 0.3s ease-in-out;
}

.nx_selectfield > .MuiInputLabel-root.Mui-focused .MuiInputLabel-root.MuiFormLabel-filled {
   transform: translate(10px, -10px) scale(0.75) !important;
   transition: transform 0.3s ease-in-out;
}

.nx_autocomplete > label {
   top: -5px !important;
}

.nx_selectfield > label {
   top: -5px !important;
}

.nx_multinumber_wrapper > label {
   top: -5px !important;
}

.nx_autocomplete > div > input {
   padding-left: 10px !important;
}

.nx_textfield_website_preview {
   top: 21px !important;
   position: absolute !important;
   left: 195px !important;
}

.nx_textfield:hover > div {
   border-radius: 8px;
   border: 1px solid rgba(45, 93, 252, 0.75);
}

.nx_textfield:focus-within > div {
   border-radius: 8px;
   border: 1px solid rgba(45, 93, 252, 1);
}

.nx_textfield > label {
   color: rgba(34, 42, 96, 0.5);
   font-family: 'Poppins' !important;
   font-size: 15px !important;
   font-style: normal;
   font-weight: 500;
   text-transform: capitalize;
   top: 2px;
}

// .nx_selectfield > label {
//   top: -5px !important;
// }

.nx_textfield:hover > label {
   font-family: 'Poppins' !important;
   font-size: 15px !important;
   font-style: normal;
   font-weight: 500;
}

.nx_textfield > div > fieldset {
   border: 0 !important;
}

.nx_textfield > div > input {
   font-size: 16px !important;
   font-family: 'Poppins' !important;
   font-style: normal;
   font-weight: 400;
}

.nx_textfield_multiline > p {
   position: relative !important;
   top: 0 !important;
}

.nx_textfield > p {
   position: absolute;
   top: 40px;
   font-size: 11px;
   line-height: 1;
   display: flex;
   align-items: flex-start;
   // white-space: pre;
}

.nx_textfield > div > textarea {
   font-size: 16px !important;
   font-family: 'Poppins' !important;
   font-style: normal;
   font-weight: 400;
}

.nx_select_helpertext {
   //  position: absolute;
   //  bottom: 0px;

   > p {
      color: #d32f2f !important;
      font-size: 11px !important;
      white-space: pre;
   }
}

.nx_multinumber_wrapper:hover > div {
   border-radius: 8px;
   border: 1px solid rgba(45, 93, 252, 0.75);
}

.nx_multinumber_wrapper:focus-within > div {
   border-radius: 8px;
   border: 1px solid rgba(45, 93, 252, 1);
}

.nx_margin > div > input {
   padding: 0 5px;
}

.nx_margin > div {
   border: 0;
}

.nx_margin:hover > div {
   border: 0;
}

.nx_margin:focus > div {
   border: 0;
}

.nx_margin:focus-within > div {
   border: 0;
}

.nx_multinumber_wrapper > div {
   position: relative;
   height: 40px;
   width: 100%;
   border-radius: 8px;
   border: 1px solid rgba(34, 42, 96, 0.5);

   > fieldset {
      border: 0 !important;
   }

   > input {
      padding: 0 10px;
      margin: 0;
   }
}

.nx_multinumber_wrapper > p {
   position: absolute;
   bottom: -15px;
   font-size: 11px;
   line-height: 1;
   display: flex;
   align-items: flex-start;
   padding: 0 0 0 15px;
   margin: 0;
}

.nx_editor {
   padding: 0;
   height: auto;
   min-height: 150px;
}

.nx_editor > div {
   min-height: 150px;
   height: auto;
}

.nx_multinumber_wrapper > label {
   color: rgba(34, 42, 96, 0.5);
   font-family: 'Poppins' !important;
   font-size: 15px !important;
   font-style: normal;
   font-weight: 500;
   text-transform: capitalize;
}

.nx_multinumber {
   position: absolute;
   bottom: 10px;
   right: 0;
   font-size: 11px;
   line-height: 1;
   display: flex;
   align-items: center;
   height: 20px;
   margin: 7.5px 40px 0 0;
   column-gap: 7px;
}

.nx_multinumber_input > div {
   color: rgba(34, 42, 96, 0.5) !important;
   font-weight: 400;
}

.nx_button_contained {
   border-radius: 8px !important;
   background: rgba(45, 93, 252, 0.9) !important;
   box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset !important;
   text-transform: uppercase !important;
}

.nx_button_outlined {
   border-radius: 8px !important;
   // margin-top: 5px !important;
}

.nx_button_outlined:hover > span {
   text-decoration: underline;
}
